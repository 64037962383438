import React from 'react'

export default function Submission({objectProp}) {
    return (
        // hình thức nộp hồ sơ (truyền trong AdmissionMethod.js)
        <div className={ objectProp.isMobile ?
            "w-full h-auto absolute top-[720px] bg-[#E6F9FD] pb-[100px]"
            : objectProp.isTablet ? "w-full h-auto absolute top-[600px] bg-[#E6F9FD] pb-[100px]"
            : objectProp.isTabletBig ? "w-full h-auto absolute top-[500px] bg-[#E6F9FD] pb-[100px]"
            : "max-w-[2000px] w-full h-auto absolute top-[420px] left-[50%] translate-x-[-50%]"
        }>
            <p className={ objectProp.isMobile ?
                "text-[25px] text-[#0046CD] font-bold text-center mt-[20px]"
                : objectProp.isTablet ? "text-[35px] text-[#0046CD] font-bold text-center"
                : "text-[35px] text-[#0046CD] font-bold ml-[80px]"
            }>HÌNH THỨC NỘP HỒ SƠ</p>

            <div className={ objectProp.isMobile ?
                "flex flex-wrap justify-evenly mt-[40px] px-[20px]"
                : objectProp.isTablet ? "flex flex-wrap justify-evenly mt-[40px] px-[20px]"
                : "flex flex-wrap justify-evenly mt-[40px]"
            }>
                <div className={ objectProp.isMobile ?
                    "w-[100%] h-auto bg-[#0046CD] rounded-[25px] box-shadow-2"
                    : objectProp.isTablet ? "w-[100%] h-auto bg-[#0046CD] rounded-[25px] box-shadow-2"
                    : "w-[35%] h-auto bg-[#0046CD] rounded-[25px] box-shadow-2"
                }>
                    <div className="bg-[#0047AD] text-white text-[25px] font-bold text-center py-[5px] rounded-t-[20px]">HỒ SƠ XÉT TUYỂN</div>
                    <div className="text-white px-[25px] py-[5px] text-[20px]">Phiếu đăng ký xét tuyển (theo mẫu); </div>
                    <div className="text-white px-[25px] py-[5px] text-[20px] text-justify">- Bản sao bằng tốt nghiệp THPT hoặc giấy chứng nhận tốt nghiệp tạm thời và bản sao Học bạ THPT (thí sinh xét tuyển là THPT); </div>
                    <div className="text-white px-[25px] py-[5px] text-[20px] text-justify">- Bản sao bằng tốt nghiệp Cao đẳng/Trung cấp hoặc giấy chứng nhận tốt nghiệp tạm thời và bản sao bảng điểm tốt nghiệp toàn khóa; </div>
                    <div className="text-white px-[25px] py-[5px] text-[20px] text-justify">- Bản sao Căn cước công dân; </div>
                    <div className="text-white px-[25px] py-[5px] text-[20px] text-justify">- 02 ảnh cở 3x4 ghi rõ họ tên, ngày sinh và nơi sinh ở mặt sau ảnh; </div>
                </div>

                <div className={ objectProp.isMobile ?
                    "w-[100%] h-auto bg-[#0046CD] rounded-[25px] box-shadow-2 mt-[50px]"
                    : objectProp.isTablet ? "w-[100%] h-auto bg-[#0046CD] rounded-[25px] box-shadow-2 mt-[50px]"
                    : "w-[35%] h-auto bg-[#0046CD] rounded-[25px] box-shadow-2"
                }>
                    <div className="bg-[#0047AD] text-white text-[25px] font-bold text-center p-[5px] rounded-t-[20px]">Địa chỉ xét tuyển & nhận hồ sơ</div>
                    <div className="text-white px-[25px] py-[5px] text-[20px] text-justify">- Harmony Campus: 624 Âu Cơ, Phường 10, Quận Tân Bình, TP. HCM. </div>
                    <div className="text-white px-[25px] py-[5px] text-[20px] text-justify">‐ HungHau Campus: Đại lộ Nguyễn Văn Linh, Khu đô thị Nam Thành phố. </div>
                    <div className="text-white px-[25px] py-[5px] text-[20px] text-justify">‐ MyU Campus: 665 - 667 - 669 Điện Biên Phủ, Phường 1, Quận 3, TP. HCM. </div>
                </div>
            </div>
        </div>
      )
}
